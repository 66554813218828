import { datadogRum } from '@datadog/browser-rum';
import config from './datadog-rum-config';

class RumServiceInterface {
    init() {
        throw new Error("init method not implemented");
    }

    startView(viewName) {
        throw new Error("startView method not implemented");
    }

    setUser(user) {
        throw new Error("setUser method not implemented");
    }
}

class DatadogRumService extends RumServiceInterface {

    constructor(datadogRumInstance, config) {
        super();
        this.config = config;
        this.datadogRumInstance = datadogRumInstance;
    }

    init(view) {
        this.datadogRumInstance.init(this.config);
        this.startView(view);
    }

    setUser(user) {
        this.datadogRumInstance.setUser(user);
    }

    startView(name) {
        this.datadogRumInstance.startView({name});
    }
}

const RUM_SERVICES = {
    datadog: (config) => new DatadogRumService(datadogRum, config),
};

class RumServiceFactory {
    static createService(serviceType, config) {
        const serviceCreator = RUM_SERVICES[serviceType];
        if (!serviceCreator) {
            throw new Error(`Unknown service type: ${serviceType}`);
        }
        return serviceCreator(config);
    }
}

module.exports = angular.module(__filename, [])
    .factory('datadogRumService', [ '$rootScope', ($rootScope) => {
        const rumService = RumServiceFactory.createService('datadog', config);
        const service = {
            currentURL: undefined,
            previousURL: undefined
        };

        const extractViewNameFromHash =  () => {
            const hash = window.location.hash || '';
            const parts = hash.split('#')[1] || '';
            const viewName = parts.split('?')[0] || 'default-view';
            return viewName;
        }

        const saveCurrentUrl = () => {
            service.currentURL = window.location.href.toString();
        }

        const startListenToLocationChange = () => {
            $rootScope.$on('$locationChangeSuccess', function () {
                const newURL = window.location.href.toString();
                if (newURL !== service.previousURL) {
                    service.previousURL = newURL;
                    rumService.startView(extractViewNameFromHash());
                }
            });
        }

        saveCurrentUrl();
        rumService.init(extractViewNameFromHash());
        rumService.setUser($rootScope.user);
        startListenToLocationChange();

        return service;
    }
])